<template>
  <div>
    <van-cell-group title="填写详情">
      <van-field label="名称" placeholder="请输入物品名称" v-model="model.NAME" >
        <template #button>
          <van-button type="primary" size="small" @click="names.show=true">查询</van-button>
        </template>
      </van-field>
      <van-popup v-model="names.show" round  position="bottom">
        <van-picker :columns="names.ls" show-toolbar @cancel="names.show=false" @confirm="namesSel">
          <template slot-scope="scope" slot="option">
            <van-cell :title="scope" />
          </template>
        </van-picker>
      </van-popup>
      <van-field v-model="model.CNT" type="digit" label="数量" placeholder="请输入数量" />
      <van-field readonly label="经手人" placeholder="请选择教师" is-link @click="teacher.show=true" v-model="model.HANDLE_NAME" />
      <van-popup v-model="teacher.show" round  position="bottom">
        <van-picker :columns="teacher.ls" show-toolbar @cancel="teacher.show=false" @confirm="teacherSel" value-key="NAME" />
      </van-popup>
      <van-field readonly label="发生日期" is-link @click="ed_show=true" v-model="model.EVENT_DT" />
      <van-calendar v-model="ed_show" @select="edSel" :show-confirm="false" />
      <van-field v-model="model.DESCRIPTION" type="textarea" label="备注" :rows="2" />
    </van-cell-group>
    <div style="margin: 15px">
      <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading" loading-text="正在提交。。。">确认提交</van-button>
      <br />
      <van-button plain type="default" size="large" icon="cross" block to="/utl/rent">取消返回</van-button>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
import moment from "moment";
export default {
  name: "new",
  data() {
    return {
      model:{
          EVENT_DT:moment().format('YYYY-MM-DD')
      },
      names:{
        show:false,
        ls:[],
        txt:""
      },
      ed_show:false,
      teacher:{
        show:false,
        ls:[],
      },
      ret:{
        loading:false
      }
    }
  },
  created() {
    this.getTeacher();
    this.getNames();
  },
  methods:{
    getNames(){
      let self=this;
      this.whale.remote.getResult({
        url:"/api/Mobile/UTL/RentApi/GetNames",
        completed:function (its){
          self.names.ls=its.DATA;
        }
      })
    },
    namesSel(o){
      this.model.NAME=o;
      this.names.show=false;
    },
    edSel(date){
      this.ed_show=false;
      this.model.EVENT_DT=moment(date).format('YYYY-MM-DD');
    },
    getTeacher(){
      let self=this;
      this.whale.remote.getCollection({
        url: "/api/Mobile/ORG/TeacherApi/GetList",
        completed: function (its) {
          self.teacher.ls = its.ITEMS;
        }
      })
    },
    teacherSel(o){
      this.model.HANDLE_NAME=o.NAME;
      this.teacher.show=false;
    },
    submit(){
      let self=this;
      self.ret.loading=true;
      this.whale.remote.getResult({
        url:"/api/Mobile/UTL/RentApi/Save",
        data:this.model,
        finally(){
          self.ret.loading=false;
        },
        completed(){
          self.$dialog.confirm({
            title:"完成",
            message:"记录已成功提交！",
            theme:"round",
            confirmButtonText:"继续录入",
            cancelButtonText:"返回"
          }).then(()=>{
            self.$router.go(0);
          }).catch(()=>{
            self.$router.push("/utl/rent")
          })
        }
      })
    }
  }
}
</script>

